import React, { useState } from "react"

import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import StaticBG from "../assets/images/uploads/home-feature-sample-3.jpg"

export default function Scholarships({ data }) {

  const [openSection, setOpenSection] = useState(false)
  const toggleSection = () => {
    setOpenSection(!openSection)
  }
return(
  <Layout toggleSection={toggleSection}>
              <Seo
        title="Scholarships"
        description="Receive scholarships, cash awards, or tuition assistance by participating in the Awards!"
      />
      {data.awardsMenu.menuItems ? (
        <nav
        role="navigation"
          id="nav-section"
          aria-label={data.awardsMenu.name}
          style={openSection === true ? { display: "block" } : null }
        >
          <ul>
            {data.awardsMenu.menuItems.nodes.map((menuItem, i) => {
              return (
                <li key={i} role="none">
                  <Link
                    key={menuItem.id}
                    to={menuItem.path}
                    tabIndex="0"
                    role="menuitem"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {menuItem.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      ) : null}
      <div className="static">
        <section
          id="landing-hero"
          style={{ backgroundImage: "url(" + StaticBG + ")" }}
        >
          <div id="landing-hero-overlay">
          Receive scholarships, cash awards, or tuition assistance by participating in the Awards!
          </div>
        </section>
        <section id="static-page" className="page-pad">
          <div className="page">
              <h2>Scholarships</h2>

                <section id="landing-features">
          <h3>Scholarships and Cash Awards</h3>
          <ul>
            {data.allDirectScholarship.edges.length === 0 ? (
              <li>No scholarships found.</li>
            ) : (
              data.allDirectScholarship.edges.map(callout => {
                if (!callout || !callout.node) {
                  console.error('Missing node data:', callout);
                  return null; // Skip this item if node data is missing
                }
                return (
                  <li key={callout.node.id}>
                    <Link to={callout.node.slug}>
                      <img
                        src={
                          callout.node.featuredImage?.node?.localFile?.childImageSharp?.resize?.src || 'default-image.jpg'
                        }
                        alt=""
                      />
                      <span className="title">{callout.node.title}</span>
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{ __html: callout.node.excerpt }}
                      ></span>
                    </Link>
                  </li>
                );
              })
            )}
          </ul>
      </section>


                {/* <section id="landing-features">
                  <h3>Scholarships and Cash Awards</h3>
                    <ul>
                      {data.allDirectScholarship.edges.map(callout => (
                          <li key={callout.node.id}>
                              <Link to ={callout.node.slug}>
                                <img
                                  src={
                                    callout.node.featuredImage.node.localFile.childImageSharp.resize.src
                                  }

                                  alt=""
                                />
                                <span className="title">{callout.node.title}</span>
                                <span className="text" dangerouslySetInnerHTML={{__html: callout.node.excerpt}}></span>
                              </Link>
                          </li>
                        ))}
                      </ul>
                  </section> */}


                <section id="landing-features">
                  <h3>Tuition Scholarships</h3>
                    <ul>
                      {data.allTuitionScholarship.edges.map(callout => (
                          <li key={callout.node.id}>
                              <Link to ={callout.node.slug}>
                                <img
                                  src={
                                    callout.node.featuredImage.node.localFile.childImageSharp.resize.src
                                  }
                                  width="400"
                                  height="400"
                                  alt=""
                                />
                                <span className="title">{callout.node.title}</span>
                                <span className="text" dangerouslySetInnerHTML={{__html: callout.node.excerpt}}></span>
                              </Link>
                          </li>
                        ))}
                      </ul>
                  </section>
            </div>
          </section>
          </div>
    </Layout>
        )
}



export const query = graphql`
{ 
  allDirectScholarship: allWpScholarship(filter: {scholarshipTypes: {nodes: {elemMatch: {name: {eq: "Direct Scholarships"}}}}}) {
    edges {
      node {
        id
        title
        excerpt
        slug
        featuredImage {
          node {
            id
            localFile {
              childImageSharp {
                id
                resize(width: 400, height: 400, cropFocus: ATTENTION) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
  allTuitionScholarship: allWpScholarship(filter: {scholarshipTypes: {nodes: {elemMatch: {name: {eq: "Tuition Scholarships"}}}}}) {
      edges {
        node {
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              id
              localFile {
                childImageSharp {
                  id
                  resize(width: 400, height: 400, cropFocus: ATTENTION) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    awardsMenu: wpMenu(slug: { eq: "awards" }) {
      name
      menuItems {
        nodes {
          label
          url
          path
          connectedNode {
            node {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
  }
`